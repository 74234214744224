import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConsts } from '@shared/AppConsts';
import { accountModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AccountServiceProxy, CommonLookupServiceProxy, PasswordComplexitySetting, ProfileServiceProxy, RegisterOutput, SocialSigninModel } from '@shared/service-proxies/service-proxies';
import { LoginService } from '../login/login.service';
import { RegisterModel } from './register.model';
import { finalize } from 'rxjs/operators';
import { SocialAuthService, SocialUser } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { DataLayerService } from '@app/main/cablepro/google-tag-manager.service';
import { NavigationService } from '@app/main/cablepro/navigation.service';
import { Title } from '@angular/platform-browser';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
    templateUrl: './register.component.html',
    animations: [accountModuleAnimation()],
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent extends AppComponentBase implements OnInit {
    model: RegisterModel = new RegisterModel();
    passwordComplexitySetting: PasswordComplexitySetting = new PasswordComplexitySetting();
    recaptchaSiteKey: string = AppConsts.recaptchaSiteKey;
    public useEmailAsUsername: boolean = true;
    saving = false;
    submitted = false;
    authLibraryReady: boolean = false;
    public selectedCountry: string = 'AU';
    public phoneNumber: string = '(+61) 02 8231 6673';

    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [CountryISO.Australia, CountryISO.Canada, CountryISO.UnitedKingdom, CountryISO.UnitedStates];
    phoneForm = new FormGroup({
        phone: new FormControl(undefined, [Validators.required])
    });

    constructor(injector: Injector, private _accountService: AccountServiceProxy,
        private _router: Router,
        private _commonLookupServiceProxy: CommonLookupServiceProxy,
        private titleService: Title,
        private readonly _loginService: LoginService,
        private _profileService: ProfileServiceProxy,
        private authService: SocialAuthService,
        private recaptchaV3Service: ReCaptchaV3Service,
        private dataLayerService: DataLayerService,
        private navigationService: NavigationService) {
        super(injector);
        this.titleService.setTitle("ELEK Cable Pro Software - Free Trial Sign Up");
        this.authService.initState.subscribe(next => { this.authLibraryReady = next });
        this.getCurrentCountry();
        (window as any).register = this;
    }

    ngOnInit() {
        //Prevent to register new users in the host context
        if (this.appSession.tenant == null) {
            this._router.navigate(['account/login']);
            return;
        }
        this._profileService.getPasswordComplexitySetting().subscribe(result => {
            this.passwordComplexitySetting = result.setting;
        });
        const previousUrl = this.navigationService.getPreviousUrl();
        this.dataLayerService.pushEvent('pageLoad', {
            Stage1: window.location.href,
            PrevURL: previousUrl,
        });
        localStorage.setItem('SrcURL', previousUrl);
    }

    ngAfterViewInit() {
        const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
        if (element) {
            element.style.visibility = 'visible';
        }
    }

    public phoneNumberValidate(event: any) {
        const pattern = /^[0-9]*$/;
        if (!pattern.test(event.target.value)) {
            event.target.value = event.target.value.replace(/[^0-9]/g, "");
        }
    }

    get useCaptcha(): boolean {
        return this.setting.getBoolean('App.UserManagement.UseCaptchaOnRegistration');
    }

    save(frm: NgForm): void {
        this.submitted = true;
        if (frm.valid && this.model.phoneNumberInput) {
            this.recaptchaV3Service.execute('importantAction')
                .subscribe((token: string) => {
                    this.model.captchaResponse = token;
                    this.saving = true;

                    document.getElementById('aUserRegistration').click();

                    this.model.emailAddress = this.model.emailAddress.trim().toLowerCase();

                    if (this.useEmailAsUsername) {
                        this.model.userName = this.model.emailAddress;
                    }
                    this.model.userName = this.model.userName.trim().toLowerCase();

                    this._accountService.register(this.model)
                        .pipe(finalize(() => { this.saving = false; }))
                        .subscribe((result: RegisterOutput) => {
                            if (!result.canLogin) {
                                this.notify.success(this.l('SuccessfullyRegistered'));
                                this._router.navigate(['account/login']);
                                return;
                            }

                            //Autheticate
                            this.saving = true;
                            this._loginService.authenticateModel.userNameOrEmailAddress = this.model.userName;
                            this._loginService.authenticateModel.password = btoa(this.model.password);
                            this._loginService.authenticate(() => { this.saving = false; }, "/app/main/usersetup", true);
                        });
                });
        }
    }

    // captchaResolved(captchaResponse: string): void {
    //     this.model.captchaResponse = captchaResponse;
    // }

    socialSignup(socialSignupModel: RegisterModel): void {
        this._accountService.socialSignup(socialSignupModel)
            .pipe(finalize(() => { this.saving = false; }))
            .subscribe((result: RegisterOutput) => {
                if (!result.canLogin) {
                    this.notify.success(this.l('SuccessfullyRegistered'));
                    this._router.navigate(['account/login']);
                    return;
                }

                //Autheticate
                this.saving = true;
                var socialSigninModel = new SocialSigninModel();
                socialSigninModel.userNameOrEmailAddress = socialSignupModel.emailAddress;

                this._loginService.socialSigninModel = socialSigninModel;
                this._loginService.socialSignin(() => { this.saving = false; }, "/app/main/usersetup", true);
            });
    }

    signUpWithGoogle(): void {
        this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((data) => {
            let socialUser = data;
            if (socialUser && socialUser.id) {
                let socialSignupModel = this.mapSocialUserData(socialUser);
                socialSignupModel.googleId = socialUser.id;
                this.socialSignup(socialSignupModel);
            }
        });
    }

    signUpWithFacebook() {
        this.authService.signIn(FacebookLoginProvider.PROVIDER_ID, { auth_type: 'reauthenticate' }).then((data) => {
            let socialUser = data;
            if (socialUser && socialUser.id) {
                let socialSignupModel = this.mapSocialUserData(socialUser);
                socialSignupModel.facebookId = socialUser.id;
                this.socialSignup(socialSignupModel);
            }
        });
    }

    mapSocialUserData(socialUser: SocialUser) {
        let socialSignupModel: RegisterModel = new RegisterModel();
        socialSignupModel.name = socialUser.firstName;
        socialSignupModel.surname = socialUser.lastName;
        socialSignupModel.emailAddress = socialUser.email;
        socialSignupModel.userName = socialUser.email;
        return socialSignupModel;
    }

    ngOnDestroy() {
        // if (this.recaptchaSubscription) {
        //   this.recaptchaSubscription.unsubscribe();
        // }
        const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
        if (element) {
            element.style.visibility = 'hidden';
        }
    }

    getCurrentCountry() {
        this._commonLookupServiceProxy.getIpInfo().subscribe((response) => {
            if (response && response.isSuccess) {
                if (response.data && response.data.country) {
                    this.selectedCountry = response.data.country;
                    if (this.selectedCountry == "GB") {
                        this.phoneNumber = "(+44) 020 3603 5333";
                    }
                    else if (this.selectedCountry == "US") {
                        this.phoneNumber = "(+1) 888 226 9651";
                    }
                }
            }
        });
    }
}
