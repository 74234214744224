export class ArcFlashData {
    arcFlash: ArcFlash;
    faultCurrent: FaultCurrent;
    label: Label;
    ppeCategory: PPECategory;
    ppeCategoriesTable: any;
    ppeCategoriesTableHeaders: any;
    BrowserName?: String;
    UserName?: String;
    CalculationId?: number;
}
export class UpdateXmlVersionData {
    
    InputXml?: String;
    CalculationType?: String;
    CalculationId?: number;
}

export class ArcFlash {
    calculationMethod: string;
    electrodeConfiguration: string;
    electrodeGap: string;
    electrodeGapDGUVDc: string;
    electrodeGapStokes: string;
    electrodeMaterial: string;
    workingDistanceIEEE: string;
    workingDistanceNENS: string;
    workingDistanceDGUVAc: string;
    workingDistanceDGUVDc: string;
    workingDistanceStokes: string;
    workingDistancePower: string;
    enclosureHeight: string;
    enclosureWidth: string;
    enclosureDepth: string;
    // phases: string;
    isCustomKB: boolean;
    currentLimitingFactor: string;
    isCustomKP: boolean;
    typeOfSystem: string;
    typeOfSystemDGUVDc: string;
    rxRatio: string;
    isCustomKT: boolean;
    isCustomKTDGUVDc: boolean;
    transmissionFactor: string;
    transmissionFactorDGUVDc: string;
    enclosureType: string;
    enclosureTypePower: string;
    customArcingCurrent: boolean;
    lowVoltageMultiplier: string;
    highVoltageMultiplier: string;
    normalisedArcPower: string;
}

export class FaultCurrent {
    supplyVoltageIEEE: string;
    boltedFaultCurrentIEEE: string;
    arcDurationIEEE: string;
    arcDurationReducedIEEE: string;
    phasesIEEE: string;

    supplyVoltageNENS: string;
    boltedFaultCurrentNENS: string;
    arcDurationNENS: string;
    phasesNENS: string;

    supplyVoltageDGUVAc: string;
    maximumFaultCurrentDGUVAc: string;
    minimumFaultCurrentDGUVAc: string;
    arcDurationDGUVAc: string;
    phasesDGUVAc: string;

    supplyVoltageDGUVDc: string;
    boltedFaultCurrentDGUVDc: string;
    arcDurationDGUVDc: string;

    supplyVoltageStokes: string;
    boltedFaultCurrentStokes: string;
    arcDurationStokes: string;

    supplyVoltagePower: string;
    boltedFaultCurrentPower: string;
    arcDurationPower: string;
}

export class Label {
    template: string;
    limitedApproachBoundryType: string;
    limitedApproachBoundaryType: string;
}

export class PPECategory {
    categorySource: string;
}


export class ArcFlashXmlInput {
    InputXmlValue?: string;
    InputJSONValue?: string;
    OutputXmlValue?: string;
    IncidentEnergy?: string;
    ArcBoundary?: string;
    CalculationMethod?: string;
    Id: number;
    ProjId: number;
}


export class ArcFlashLabelDiagramModel {
    ppeCategory: string;
    calculationMethod: string;
    template: string;
    supplyVoltage: string;
    outputXml: string;
    calculationName: string;
    labelArray: any;
    labelArrayAll: any;
    labelNameArrayAll: any;
    calculationId: number;

}