
import { AbpModule } from '@abp/abp.module';
import * as ngCommon from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuickSideBarChat } from '@app/shared/layout/chat/QuickSideBarChat';
import { ChatSignalrService } from '@app/shared/layout/chat/chat-signalr.service';
import { LinkAccountModalComponent } from '@app/shared/layout/link-account-modal.component';
import { LinkedAccountsModalComponent } from '@app/shared/layout/linked-accounts-modal.component';
import { LoginAttemptsModalComponent } from '@app/shared/layout/login-attempts-modal.component';
import { ChangePasswordModalComponent } from '@app/shared/layout/profile/change-password-modal.component';
import { ChangeProfilePictureModalComponent } from '@app/shared/layout/profile/change-profile-picture-modal.component';
import { MySettingsModalComponent } from '@app/shared/layout/profile/my-settings-modal.component';
import { SmsVerificationModalComponent } from '@app/shared/layout/profile/sms-verification-modal.component';
import { ServiceProxyModule } from '@shared/service-proxies/service-proxy.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { FileUploadModule } from 'ng2-file-upload';
import { FileUploadModule as PrimeNgFileUploadModule, PaginatorModule, ProgressBarModule } from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { ImpersonationService } from './admin/users/impersonation.service';
import { FileUploadSendEmailService } from '@app/shared/common/fileupload/file-upload.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppCommonModule } from './shared/common/app-common.module';
import { ChatBarComponent } from './shared/layout/chat/chat-bar.component';
import { ChatFriendListItemComponent } from './shared/layout/chat/chat-friend-list-item.component';
import { ChatMessageComponent } from './shared/layout/chat/chat-message.component';
import { FooterComponent } from './shared/layout/footer.component';
import { HeaderComponent } from './shared/layout/header.component';
import { LinkedAccountService } from './shared/layout/linked-account.service';
import { SideBarMenuComponent } from './shared/layout/nav/side-bar-menu.component';
import { TopBarMenuComponent } from './shared/layout/nav/top-bar-menu.component';
import { UserNotificationHelper } from './shared/layout/notifications/UserNotificationHelper';
import { HeaderNotificationsComponent } from './shared/layout/notifications/header-notifications.component';
import { NotificationSettingsModalComponent } from './shared/layout/notifications/notification-settings-modal.component';
import { NotificationsComponent } from './shared/layout/notifications/notifications.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSliderModule } from '@angular/material/slider';
import { Ng5SliderModule } from 'ng5-slider';
// import { TreeviewModule } from 'ngx-treeview';
import { TreeModule } from 'angular-tree-component';
import { HttpCancelService } from 'services/project.service';
import { ManageHttpInterceptor } from 'services/project.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { OrderModule } from 'ngx-order-pipe';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxCaptureModule } from 'ngx-capture';
import { GoogleTagManagerService } from './main/cablepro/google-tag-manager.service';
import { GTMGuard } from './main/cablepro/gtm-gurad';
import { PopoverModule } from "ngx-smart-popover";
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SocialLoginModule, SocialAuthServiceConfig, FacebookLoginProvider } from 'angularx-social-login';
import {
    GoogleLoginProvider
} from 'angularx-social-login';
import { environment } from 'environments/environment';
import { LoaderComponent } from './main/cablepro/loader.component';
import { LoaderService } from './main/cablepro/loader.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from './shared/common/confirmation-modal/confirmation-modal.component';
import { NavigationService } from './main/cablepro/navigation.service';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        LoaderComponent,
        HeaderNotificationsComponent,
        SideBarMenuComponent,
        TopBarMenuComponent,
        FooterComponent,
        LoginAttemptsModalComponent,
        LinkedAccountsModalComponent,
        LinkAccountModalComponent,
        // ChangePasswordModalComponent,
        ChangeProfilePictureModalComponent,
        // MySettingsModalComponent,
        // SmsVerificationModalComponent,
        NotificationsComponent,
        ChatBarComponent,
        ChatFriendListItemComponent,
        NotificationSettingsModalComponent,
        ChatMessageComponent,
        ConfirmationModalComponent
    ],
    imports: [
        ngCommon.CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        TabsModule.forRoot(),
        FileUploadModule,
        AbpModule,
        AppRoutingModule,
        UtilsModule,
        AppCommonModule.forRoot(),
        ServiceProxyModule,
        TableModule,
        PaginatorModule,
        NgxPaginationModule,
        PrimeNgFileUploadModule,
        ProgressBarModule, BrowserAnimationsModule, MatTooltipModule, MatSliderModule, Ng5SliderModule
        , TreeModule.forRoot(),
        OrderModule,
        NgxCaptureModule,
        PopoverModule,
        NgSelectModule,
        NgxSkeletonLoaderModule.forRoot(),
        SocialLoginModule
    ],
    providers: [
        ImpersonationService,
        LinkedAccountService,
        UserNotificationHelper,
        ChatSignalrService,
        QuickSideBarChat,
        NgbActiveModal,
        FileUploadSendEmailService,
        HttpCancelService,
        GoogleTagManagerService,
        LoaderService,
        NavigationService,
        GTMGuard,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            environment.GoogleClientId,
                            {
                                scope: 'profile email',
                                plugin_name: 'CablePro', //can be any name
                                prompt: 'select_account'
                            }
                        )
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(
                            environment.FacebookClientId,
                            {
                                scope: 'email',
                                return_scopes: true,
                                auth_type: 'reauthenticate',
                                enable_profile_selector: true
                            }
                        )
                    }
                ],
                onError: (err) => {
                    console.error(err);
                }
            } as SocialAuthServiceConfig,
        },        
        // ,
        // { provide: HTTP_INTERCEPTORS, useClass: ManageHttpInterceptor, multi: true }
    ]
})
export class AppModule { }
