// "Production" enabled environment

export const environment = {
    production: false,
    // envName: 'dev',
    hmr: false,
    appConfig: 'appconfig.json',
    GoogleTag: 'GTM-MPWF8FGT',
    GoogleClientId: '879001635616-ktqpsf12bbi55kh4ut8auvuio28bk0so.apps.googleusercontent.com',
    FacebookClientId: '3721145298155846',
    RecaptchSiteKey: "6LdH5FspAAAAADw0kIW5v05ZxicwYRu5Xe2g7tNE"
};
